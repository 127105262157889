export class CompanyBasic {
  no: string;
  name: string;
  country: string;
}

export class BcUser {
  id?: string;
  email?: string;
  fullname?: string;
  token?: string;
  tokenexpirationdate?: Date;
  roles: string[] = [];
  isLsAdmin: boolean = false;
  isBcViewer: boolean = false;
  company?: string;
  country?: string;
  bcAdminCompanies: CompanyBasic[] = [];
  authenticated: boolean = false;
}
